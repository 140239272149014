var resizeTimer;
var mobile_trigger_width_xs = 768;
var mobile_trigger_width_sm = 980;

$(function () {
    initCarousel();
    initMagnific();
    initNav();
    initContact();
    initFaq();
    initBackToTop();
    initRegistration();
    initTooltip();

    initPageImages();

    if ($('#WhatIsACookie').length > 0) {
        //Uitzondering en geen medling tonen
    } else {
        initCookiebox();
    }

    // Resize
    $(window).resize(function () {
        clearTimeout($.data(this, 'resizeTimer'));
        $.data(this, 'resizeTimer', setTimeout(function () {
            initNav();
        }, 100));
    });
});

$(window).scroll(function () {
    if ($(this).scrollTop()) {
        $('#toTop').fadeIn();
    } else {
        $('#toTop').fadeOut();
    }
});

// 
// REGISTRATION
// 
var initRegistration = function () {
    if ($('.js-registration--statement').length > 0) {
        initRegistrationCheckStatement();
        $("input[name='statement_good_behavior']").change(function () {
            initRegistrationCheckStatement();
        });
    }
}
var initRegistrationCheckStatement = function () {
    if ($("input[name='statement_good_behavior']:checked").val() == '0') {
        $('.js-registration--statement').show();
    } else {
        $('.js-registration--statement').hide();
    }
}


//
// NAVIGATION
//
var initNav = function () {
    // SHOW MOBILE OR DEKSTOP MENU
    $('body').css('padding-bottom', '0px');
    if ($(window).width() < mobile_trigger_width_xs) {
        $('.c-header--nav').slicknav({
            label: '',
            appendTo: '.c-header--mobilenav',
            easingOpen: "swing",
            easingClose: "swing",
            closedSymbol: '<i class="fa fa-chevron-right" aria-hidden="true"></i>',
            openedSymbol: '<i class="fa fa-chevron-down" aria-hidden="true"></i>'
        });
        $('.c-header--nav').hide();
        $('.c-header--mobilenav').show();
        $('.slicknav_menu').show();

        const header = document.querySelector('.c-header');
        if (header) {
            header.style.backgroundImage = header.style.backgroundImage.replace('.jpg', '-mobile.jpg');
        }
    } else {
        $('.c-header--nav').show();
        $('.slicknav_menu').hide();
        $('.c-header--mobilenav').hide();
    }
    // HEADER FIXED => BODY PADDING
    // if ($(window).width() < mobile_trigger_width_xs) {
    //     $('body').css('padding-top', '70px');
    // } else {
    //     $('body').css('padding-top', $('.c-header').height());
    // }
}

//
// CAROUSEL
//
var initCarousel = function () {
    if ($('.c-carousel--body').length > 0) {
        $('.c-carousel--body').slick({
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000
        });
        $('.c-carousel--body button.slick-prev').html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
        $('.c-carousel--body button.slick-next').html('<i class="fa fa-angle-right" aria-hidden="true"></i>');

        if ($('.c-carousel--body .slick-slide').length <= 1) {
            $('.slick-dots').hide();
        }

        $('.c-carousel--body').delay(5000).css('height', 'auto');
    }

    if ($('.c-homepage--logos__carousel').length > 0) {
        $('.c-homepage--logos__carousel').slick({
            dots: true,
            arrows: false,
            autoplay: false,
            autoplaySpeed: 4000,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        if ($('.c-homepage--logos__carousel .slick-slide').length <= 1) {
            $('.slick-dots').hide();
        }
    }
}

//
// MAGNIFIC
//
var initMagnific = function () {
    if ($('.magnific').length > 0) {
        $('.magnific').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {
                verticalFit: true
            }
        });
    }
}

//
// FAQ
//
function initFaq() {
    if ($('.c-faq--overview').length) {
        sessionStorage.SessionName = "FaqRating";

        $('.groups .group').each(function () {
            var $group = $(this);
            $(this).find('h3.heading').click(function () {
                $group.toggleClass('active');
            });

            $(this).find('.questions .question').each(function () {
                var $question = $(this);
                $(this).find('strong.heading').click(function () {
                    $question.toggleClass('active');
                });

                $(this).find('.rating input[type="radio"]').each(function () {

                    if (sessionStorage.getItem($(this).attr('name'))) {
                        $(this).attr('disabled', 'disabled');

                        if (sessionStorage.getItem($(this).attr('name')) === 'yes') {
                            if ($(this).attr('value') === 'yes') {
                                $(this).attr('checked', 'checked');
                            }
                        } else if (sessionStorage.getItem($(this).attr('name')) === 'no') {
                            if ($(this).attr('value') === 'no') {
                                $(this).attr('checked', 'checked');
                            }
                        }
                    }

                    $(this).click(function () {
                        $('input[name="' + $(this).attr('name') + '"]').attr('disabled', 'disabled');
                        sessionStorage.setItem($(this).attr('name'), $(this).attr('value'));

                        var array = [$(this).attr('name'), sessionStorage.getItem($(this).attr('name'))];
                        var json = JSON.stringify(array);

                        $.ajax({
                            dataType: 'json',
                            method: 'PUT',
                            headers: {
                                'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/faq/rating',
                            data: {
                                data: json
                            }
                        }).done();

                        var $rating = $(this).parents('.rating');

                        $rating.append('<p class="flash-success alert-success">Bedankt voor uw stem.</p>').fadeIn('slow');

                        setTimeout(function () {
                            $rating.find('.flash-success').fadeOut('slow');
                        }, 5000);

                        $rating.find('.flash-success').click(function () {
                            $(this).fadeOut('slow');
                        });
                        setTimeout(function () {
                            $rating.find('.flash-success').remove();
                        }, 6000);

                        $(this).attr('disabled', 'disabled');
                    });
                });
            });
        });
    }
}

//
// CONTACT
//
var initContact = function () {
    if ($('.c-googlemap').length > 0) {
        var marker_lat = $('.c-googlemap').data('lat');
        var marker_lng = $('.c-googlemap').data('lng');
        var marker_latlng = {lat: marker_lat, lng: marker_lng};
        var marker_title = $('.c-googlemap').data('data-title');
        var marker_icon = {
            url: '/img/marker.png'
        }

        var map = new google.maps.Map(document.getElementById('googleMaps'), {
            zoom: 14,
            center: marker_latlng,
            disableDefaultUI: true
        });

        var infowindowTitle = $('.c-googlemap').attr('data-infowindowtitle');
        var infowindowAddress = $('.c-googlemap').attr('data-infowindowaddress');
        var infowindowZipcode = $('.c-googlemap').attr('data-infowindowzipcode');
        var infowindowCity = $('.c-googlemap').attr('data-infowindowcity');
        var infowindowEmail = $('.c-googlemap').attr('data-infowindowemail');
        var infowindowPhone = $('.c-googlemap').attr('data-infowindowphone');

        var contentString = '<div class="c-infowindow">' +
            '<h1>' + infowindowTitle + '</h1>' +
            '<p>' +
            '<b>' + infowindowAddress + '</b><br>' +
            infowindowZipcode + ' ' + infowindowCity +
            '</p>' +
            '<p>' +
            '<a href="tel:' + infowindowPhone + '" ="Bel direct naar ' + infowindowTitle + '"><span class="fa fa-phone"></span>' + infowindowPhone + '</a><br>' +
            '<a href="mailto:' + infowindowEmail + '" ="Stuur een e-mail naar ' + infowindowTitle + '"><span class="fa fa-envelope"></span>' + infowindowEmail + '</a>' +
            '</p>' +
            '</div>';

        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });

        var marker = new google.maps.Marker({
            position: marker_latlng,
            map: map,
            title: marker_title,
            icon: marker_icon
        });
        marker.addListener('click', function () {
            infowindow.open(map, marker);
        });
    }
}

//
// INVISIBLE CAPTCHA
//
function invisibleReCaptchaContactForm() {
    console.log('callback');
    try {
        document.getElementById('frmAanmelden').submit();
    } catch (ex) {
        //
    }
    try {
        document.getElementById('frmContact').submit();
    } catch (ex) {
        //
    }
}

//
// INVISIBLE CAPTCHA
//
function invisibleReCaptchaHercertificerenForm() {
    console.log('callback');
    try {
        document.getElementById('frmHercertificeren').submit();
    } catch (ex) {
        //
    }
}

//
// BACK TO TOP
//
var initBackToTop = function () {
    $("#toTop").click(function (event) {
        $("html, body").animate({scrollTop: 0}, 1000);
    });
}

//
// TOOLTIP
//
var initTooltip = function () {
    $('[data-toggle="tooltip"]').tooltip()
}

//
// COOKIE BOX
//
var initCookiebox = function () {
    var cookielevels = ['1', '2', '3'];

    if (cookielevels.indexOf(getCookie('cookielevel')) === -1) {
        $.fancybox('#cookiebox-nieuw', {
            closeBtn: false,
            helpers: {
                overlay: {
                    closeClick: false
                }
            }
        });

        $('#cookiebox-nieuw .btn-cookie').click(function () {
            setCookie('cookielevel', 3, 365);

            $.fancybox.close();

            location.reload();
        });

        $('#cookiebox-nieuw .btn-cookie_not-accept').click(function () {
            $.fancybox.close();

            setTimeout(function () {
                $.fancybox('#cookiebox-settings', {
                    closeBtn: false,
                    helpers: {
                        overlay: {
                            closeClick: false
                        }
                    }
                });
            }, 500);
        });

        $('#cookieform').submit(function (e) {
            e.preventDefault();

            setCookie('cookielevel', $('input[name="cookiesetting"]:checked').val(), 365);

            $.fancybox.close();

            location.reload();
        });
    }
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

function initPageImages() {
    $('img').each(function () {
        var width = $(this).attr('width');

        if (typeof width !== typeof undefined && width !== false) {
            $(this).css({
                width: width
            });
        }
    });
}